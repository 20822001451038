<template>
	<div class="main">
		<!-- <div class="logo">
<img src="../assets/logo.png" alt />
<span>{{ $t('login.title') }}</span>
</div> -->
		<div class="login">
			<div class="welcome">欢迎登录</div>
			<div class="sys">乐开门后台管理系统</div>
			<el-form class="login-form" :rules="rules" :model="form" ref="form">
				<el-form-item prop="account" class="inputCSS">
					<el-input
						type="text"
						v-model="form.account"
						:placeholder="$t('login.accoutPla')"
						auto-complete="off"
						style="font-size: 20px"
						prefix-icon="el-icon-user-solid"
					></el-input>
				</el-form-item>
				<el-form-item prop="password" class="inputCSS">
					<el-input
						type="password"
						v-model="form.password"
						:placeholder="$t('login.passwordPla')"
						auto-complete="off"
						prefix-icon="el-icon-lock"
					></el-input>
				</el-form-item>
				<el-checkbox v-model="rememberPasswords">{{ $t('login.remember') }}</el-checkbox>
				<el-form-item>
					<button class="login_btn" width="100%" type="button" @click="login">
						{{ $t('login.in') }}
					</button>
				</el-form-item>
			</el-form>
		</div>
		<div class="copyright">
			<!-- <a href="http://www.beian.miit.gov.cn/" target="_blank"> -->
			<span>
				广州科升信息科技有限公司 Copyright©2019-2020 All Rights Reserved.
				<br />
				推荐浏览器：Chrome、Firefox、IE10及其以上版本。推荐分辩率：1366*768或者更高。 用户使用手册
			</span>
			<!-- </a> -->
		</div>
	</div>
</template>
<script>
export default {
	name: 'login',
	data() {
		return {
			rules: {
				password: [
					{ required: true, message: '请输入密码', trigger: 'blur' }
					// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
				],
				account: [{ required: true, message: '请输入账号', trigger: 'blur' }]
			},
			form: {
				account: '',
				// account: '13600000000',
				// password: '123456',
				password: '',
				app: 'adminsys',
				terminal: 'computer'
			},
			rememberPasswords: true
		}
	},
	mounted() {
		if (localStorage.getItem('myNote')) {
			const myNote = JSON.parse(localStorage.getItem('myNote'))
			this.form = Object.assign({}, this.form, myNote)
		}
	},
	methods: {
		login() {
			this.$refs['form'].validate(valid => {
				if (!valid) {
					return
				}
				this.ajax.post('/api/login', this.form).then(data => {
					if (data) {
						if (this.rememberPasswords) {
							localStorage.setItem(
								'myNote',
								JSON.stringify({
									account: this.form.account,
									password: this.form.password
								})
							)
						}
						this.$message({
							message: this.$t('login.loginSuccess'),
							type: 'success'
						})
						localStorage.setItem('sessionInfo', JSON.stringify(data))
						this.getAllAppEnumType()
						// this.getCurResoures();
					}
				})
			})
		},
		getCurResoures() {
			let form = {
				resourceType: this.form.app
			}
			this.ajax.get('/api/getCurUserResources', form).then(res => {
				localStorage.setItem('permissList', JSON.stringify(res.map(e => e.resourceCode)))
				this.$store.commit(
					'getPermissList',
					res.map(e => e.resourceCode)
				)
				this.$router.push('/home')
			})
		},
		getAllAppEnumType() {
			this.ajax.get(`/common/appEnumType/getAllAppEnumType`).then(res => {
				localStorage.setItem('enumType', JSON.stringify(res))
				this.$store.commit('getEnum', res)
				this.getCurResoures()
			})
		}
	}
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url(../assets/image/login-bg.png);
	background-size: cover;
}

.login {
	width: 540px;
	height: 620px;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-image: url(../assets/image/login.png);
	text-align: center;
	.welcome {
		height: 35px;
		font-size: 36px;
		font-family: Source Han Sans SC;
		font-weight: 500;
		color: #ffffff;
		margin-top: 80px;
	}
	.sys {
		height: 41px;
		font-size: 42px;
		font-family: Source Han Sans SC;
		font-weight: bold;
		color: #ffffff;
		margin-top: 20px;
	}
}
::v-deep .el-form {
	margin-top: 80px;
}

::v-deep .el-input__inner {
	background: transparent;
	color: #fff;
}

::v-deep .el-input input {
	padding-left: 43px;
	padding-top: 6px;
	font-size: 14px;
	width: 340px;
	height: 40px;
}

::v-deep .el-input__prefix {
	left: 110px;
	color: #fff;
}

::v-deep .el-form-item__error {
	margin-left: 105px;
	padding-bottom: 5px;
}

// dibu
.copyright {
	width: 100px;
	height: 60px;
	line-height: 20px;
	// background: rgba(0, 20, 74, 1);
	position: absolute;
	bottom: 0;
	left: 0;
	font-size: 12px;
	color: #fff;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	a {
		display: block;
		// margin-top: 10px;
	}
}
.mb50 {
	margin-bottom: 50px;
}
.el-checkbox {
	position: absolute;
	right: 110px;
}
.el-checkbox__inner {
	background-color: transparent !important;
}
::v-deep .el-form-item__content button {
	width: 340px;
	height: 40px;
	background: #2998e8;
	border: none;
	outline: none;
	color: #fff;
	font-size: 18px;
	font-family: Source Han Sans CN;
	font-weight: bold;
	text-align: center;
	top: 40px;
	left: 100px;
	position: absolute;
	border-radius: 4px;
	cursor: pointer;
}
</style>
